import { Link, useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./home.css";
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { OurServicesData, brandsImg } from "../utils/dummydata";
import LOCAL_IMAGES from "../utils/localImages";
import { BsInstagram } from "react-icons/bs";
import NewFooter from "../footer";
import Testimonial from "../../components/Testimonial";
import Header from "../../components/Header";
import InstagramCart from "../../components/InstagramCart";
import ServiceCart from "../../components/ServiceCart";
import Banner from "../../components/banners/Banner";
import ScrollAnimation from 'react-animate-on-scroll';
// import "animate.css/animate.compat.css";
const banner1 = require("../../../src/assets/images/banner1.jpeg");
const banner2 = require("../../../src/assets/images/banner2.jpg");
const banner3 = require("../../../src/assets/images/banner3.jpg");
const newsmdiaData = [
  // {
  //   videourl:LOCAL_IMAGES.inst1Icon
  // },
  {
    videourl: LOCAL_IMAGES.inst12Icon,
  },
  // {
  //   videourl:LOCAL_IMAGES.inst13Icon
  // },
  {
    videourl: LOCAL_IMAGES.inst14Icon,
  },
  {
    videourl: LOCAL_IMAGES.inst15Icon,
  },
  {
    videourl: LOCAL_IMAGES.inst16Icon,
  },
  // {
  //   videourl:LOCAL_IMAGES.inst17Icon
  // },
  // {
  //   videourl:LOCAL_IMAGES.inst18Icon
  // },
  // {
  //   videourl:LOCAL_IMAGES.inst19Icon
  // },
  // {
  //   videourl:LOCAL_IMAGES.inst10Icon
  // },
  // {
  //   videourl:LOCAL_IMAGES.inst11Icon
  // },
  {
    videourl: LOCAL_IMAGES.inst212Icon,
  },
];

export default function Home() {
  const navigate = useNavigate();
  const GTM_ID = "GTM-T37QZ7DB";
  const exploremore = () => {
    navigate("/video");
  };
  const newSettings = {
   dots:false,

  };
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    pauseOnHover: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
    // arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          //   dots: true
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settingsreview = {
    dots: true,
    // infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    // arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          //   dots: true
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      bgimge: LOCAL_IMAGES.quoteicon,
      para: "I brought my daughter to smart salon for her first haircut, and the experience was wonderful.The stylist was patient and gentle,and they made it a memorable and positive experience for her.",
      name: "Arpita Solanki",
      nameImg:
        "https://argatinkeratin.com/wp-content/uploads/2022/07/download_1.jpg",
    },
    {
      bgimge: LOCAL_IMAGES.quoteicon,
      para: "I've been to many salons in the past, but smart Salon stands out for its professionalism and attention to detail. The quality of service and products they use is top-notch. I won't go anywhere else.",
      name: "Roshni panchal",
      nameImg:
        "https://argatinkeratin.com/wp-content/uploads/2022/07/istockphoto-578811140-612x612-1.jpg",
    },
    //  {
    //   bgimge:LOCAL_IMAGES.quoteicon,
    //   para:"I went to Smart Salon for a bridal hair and makeup trial, and I was blown away by the results. The team really listened to my vision and brought it to life. I can't wait for them to make me look stunning on my wedding day!",
    //   name:'Kanchan Pawar',
    //   nameImg:'https://argatinkeratin.com/wp-content/uploads/2022/07/istockphoto-578811140-612x612-1.jpg'

    //  },
    {
      bgimge: LOCAL_IMAGES.quoteicon,
      para: "I was nervous about trying a new salon, but Smart Salon exceeded all my expectations. The staff is knowledgeable about the latest trends and techniques, and they made me feel comfortable and valued as a customer.",
      name: "Priyanka Praksh",
      nameImg:
        "https://argatinkeratin.com/wp-content/uploads/2022/07/images.jpg",
    },

    {
      bgimge: LOCAL_IMAGES.quoteicon,
      para: "As someone who's always struggled with hair color, I was nervous about trying something new. But Smart Salon team of experts put me at ease. My hair has never looked so beautiful and vibrant!",
      name: "Priya Singh",
      nameImg:
        "https://argatinkeratin.com/wp-content/uploads/2022/07/download.jpg",
    },
  ];
  const onpresspaper = () => {
    navigate("/salondiscription");
  };
  const gradientStyle = {
    backgroundImage: "linear-gradient(to right, #bdc3c7, #2c3e50)", // Adjust gradient colors
    height: "380px",
    marginTop: 5,
    width: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "60px",
  };
  const handleInstagramNavigate = () => {
    window.location.href = "https://www.instagram.com/monsoonsalon/";
  };
  const bannerImages = [
    // banner1,
    banner3,
    LOCAL_IMAGES.banner4,
    LOCAL_IMAGES.banner6,
  ];
  return (
    <>
      <div className="roboto-regular ">
        <Helmet>
          <title>Smart Salon - India's Leading Salon Chain</title>
          <meta
            name="description"
            content="Welcome to Smart Salon Franchise, the largest and most trusted salon chain in India. Join a leading salon brand with a proven business model designed for success."
          />
          <meta
            name="keywords"
            content="salon franchise, salon chain in india, salon franchise portal, affordable salon chain in india"
          />
                  <script>{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-T37QZ7DB');
      `}</script>

      {/* Google Tag Manager (noscript) */}
       <noscript>
       {` <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T37QZ7DB"
          height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}>
        </iframe>`}
      </noscript>
        </Helmet>
        <div className="mainsliderContainer">
          <Carousel
            showArrows={false}
            showStatus={false}
            showIndicators={true}
            infiniteLoop={true}
            autoPlay={true}
            interval={2000}
            pauseOnHover={false}
            showThumbs={false}
          >
            {bannerImages?.map((image, index) => (
              <div key={index} className="h-full ">
                <Banner image={image} />
              </div>
            ))}
          </Carousel>
        </div>

        {/* <Container> */}
        <div className="my-5 w-[95%] mx-auto  md:my-10 flex flex-wrap justify-around gap-y-4 gap-x-5  items-center">
          <Link
            to="/monsoon"
            className="p-3 w-[45%] lg:w-auto  md:p-9 bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
            state={{ data: "Monsoon" }}
          >
            <img
              src={LOCAL_IMAGES.monsoonlogo}
              className="h-full"
              alt="monsoonlogo"
            />
          </Link>
          <Link
            to="/monsoonpro"
            className="p-3 w-[45%] lg:w-auto md:p-9 bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
            state={{ data: "MonsoonPro" }}
          >
            <img
              src={LOCAL_IMAGES.New_MonsoonPro_Logo}
              className="h-full"
              alt="monsoon pro"
            />
          </Link>
          <Link
            to="/proplus"
            className="p-3 w-[45%] lg:w-auto md:p-9 bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
            state={{ data: "SmartSalon" }}
          >
            <img
              src={LOCAL_IMAGES.NewProLogo}
              className="h-full"
              alt="smart salon"
            />
          </Link>
        </div>

        {/* <HomapgeAbout/> */}
             <Header />

        {/* <MainText textdata={"Our Services"}/> */}
        <ScrollAnimation  animateOnce={true} animateIn="animate__fadeInDown">

        <h1 className="text-4xl text-center font-normal roboto-regular opacity-80 mb-5">
          Our Services
        </h1>
        </ScrollAnimation>

        <div className=" w-full grid grid-cols-1  sm:grid-cols-2 place-items-center justify-items-center lg:grid-cols-3 xl:grid-cols-4 px-5 md:mb-10">
          {OurServicesData.map((item, index) => (
            <ServiceCart
              index ={index}
              img={item.serviceIcon}
              name={item.serviceName}
              desc={item.serviceDiscipton}
            />
          ))}
        </div>

        <div className="mt-5 md:mt-10">
          <h1 className="text-xl text-center font-medium roboto-regular opacity-75">
            Show Some Love
          </h1>
          <h1 className="text-2xl md:text-4xl text-center font-normal roboto-regular opacity-80 mb-5">
            Follow us on Instagram
          </h1>

          <div className="w-full h-auto flex justify-around md:justify-between items-center gap-y-5 gap-x-5 px-4 md:px-40 flex-wrap md:flex-nowrap">
            {newsmdiaData?.map((item) => (
              <InstagramCart img={item.videourl} />
            ))}
          </div>
          <div className="flex items-center justify-center mt-3">
            <button
              className=" flex cursor-pointer bg-black text-white gap-x-3 items-center px-4 py-2 rounded-lg hover:brightness-90"
              onClick={handleInstagramNavigate}
            >
              <BsInstagram />
              View on Instagram
            </button>
          </div>
        </div>
        {/* <BookingSection/> */}
        <Testimonial />
     
        <h1 className="text-md md:text-xl text-center font-medium roboto-regular opacity-75">
          We work with the Best
        </h1>
        <h1 className="text-2xl md:text-4xl text-center font-normal roboto-regular opacity-80 mb-5">
          Partner Brands
        </h1>
        <div className="mb-16">
          <Slider {...{ ...settings, ...newSettings }}>
            {brandsImg?.map((item, index) => {
              return (
                <div
                key={index}
                  style={{
                    height: "50px",
                    width: "50px",
                    backgroundColor: "green",
                  }}
                >
                  <img
                    src={item.brandUrl}
                    style={{ height: "60%", width: "50%" }}
                    alt="galleryimages"
                  />
                </div>
              );
            })}
          </Slider>
        </div>
        <NewFooter />
      </div>
   
    </>
  );
}
