import React, { useState } from 'react'
import { Button, Grid, TextField, Typography ,Box, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import axios from 'axios';


export default function FormPage() {
    const [firstname, setFirstName] = useState("");
    const [nameError, setNameError] = useState("");
    const [phoneno, setPhoneno] = useState("");
    const [phoneError, setphoneError] = useState();
    const [service, setService] = useState('')
    const salonService = [
        {
          name: "Hair",
        },
        {
          name: "Beauty",
        },
        {
          name: "Make up",
        },
        {
          name: "Nails",
        },
      ];

    const validatePhoneNumber = () => {
        const Regex = /^([0-9]{10})$/;
        const emailRgx =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const nameRegexMin2 = /^[a-zA-Z ]{2,50}$/;
        if (phoneno === "") {
          setphoneError("Phone Number field cannot be empty");
        } else if (!Regex.test(phoneno)) {
          setphoneError("Invalid phone number");
        } else {
          setphoneError("");
        }
      
        if (firstname === "") {
          setNameError("Name field cannot be empty");
        } else if (!nameRegexMin2.test(firstname)) {
          setNameError("Invalid Name");
        } else {
          setNameError("");
        } 
      };
      const handleChangeName = (e) => {
        setFirstName(e.target.value);
      };
      const handleChange = (e) => {
        setPhoneno(e.target.value);
      };

      const handlePostapiCall = () => {
        var data = {
          name: firstname,
          phoneNumber: phoneno,
          service:service
        };
        axios
          .post("https://smartsalon.in/api/sendMailToParlor", data)
          
          .then(function (response) {
            // handle success
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
      };

      const onValidation = () => {
        validatePhoneNumber();
          handlePostapiCall();
          alert('Form submitted successfully!');
      
      };
      const handleChangeservice = (e) => {
        setService(e.target.value)
    
      }
   
    
  return (
    <> 
      <div>

<Box sx={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'150px'}}> <Typography sx={{color:'grey',fontSize:'20px',fontWeight:'700'}}>Fill Your Details and get 20% Discount</Typography></Box>
</div>
    <div
    style={{
      backgroundColor: '',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Adjusted to take the full viewport height
    }}
  >
  
    
    <Box
      component="div"
      style={{
        width: '50%', // Adjusted to center the form and control its width
        textAlign: 'center',
        padding: '20px',
        border: '1px solid #ccc', // Added a border for visual separation
      }}
    >
      <TextField
        id="outlined-basic"
        label="First Name"
        variant="outlined"
        size="large" // Adjusted to increase the text field size
        fullWidth
        required
        inputProps={{ style: { color: 'grey', fontSize: 18 } }}
        InputLabelProps={{
          style: { color: '#626A70', fontWeight: '700' },
        }}
        onChange={handleChangeName}
      />
      <Typography style={{ color: 'red', marginBottom: '10px' }}>
        {nameError}
      </Typography>
      <TextField
        id="outlined-basic"
        label="Number"
        variant="outlined"
        size="large" // Adjusted to increase the text field size
        fullWidth
        required
        inputProps={{ style: { color: 'grey', fontSize: 18 } }}
        InputLabelProps={{
          style: { color: '#626A70', fontWeight: '700' },
        }}
        onChange={handleChange}
      />
      <Typography style={{ color: 'red', marginTop: '10px' }}>
        {phoneError}
      </Typography>
      <Grid item lg={6} xs={12}>
              <FormControl
                size="medium"
                // style={{width:'502px'}}
                fullWidth
              >
                <InputLabel id="demo-simple-select-label">
                  Service Interested In*

                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={service}
                  size="small"
                  label="Service Interested In*"
                  onChange={handleChangeservice}
                  width

                >
                  {
                    salonService?.map((item, index) => {
                      return (<MenuItem value={item.name}>{item.name}</MenuItem>)
                    })
                  }

                </Select>
              </FormControl>

            </Grid>
      <Button
        variant="contained"
        size="large"
        onClick={onValidation}
        sx={{ marginTop: 2, bgcolor: 'grey' }}
      >
        Submit
      </Button>
    </Box>
  </div>
  </>
  )
}



