import React, { useState } from 'react';
import './salon.css';
import { Grid, TextField, Typography, Button } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

export default function Salondiscription() {
  const [firstname, setFirstName] = useState('')
  const [nameError, setNameError] = useState('')
  const [lastName, setLastName] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [phoneno, setPhoneno] = useState('')
  const [phoneError, setphoneError] = useState()
  const [comment, setComment] = useState('')
  const [captchaValue, setCaptchaValue] = useState(null);
  
  const validatePhoneNumber = () => {
    const Regex = /^([0-9]{10})$/;
    const emailRgx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const nameRegexMin2 = /^[a-zA-Z ]{2,50}$/;
    if (phoneno === "") {
      setphoneError("Phone Number field cannot be empty");
    } else if (!Regex.test(phoneno)) {
      setphoneError("Invalid phone number");
    } else {
      setphoneError("");
    }
    if (email === "") {
      setEmailError("Email field cannot be empty");
    } else if (!emailRgx.test(email)) {
      setEmailError("Invalid Email Id");
    } else {
      setEmailError("");
    }
    if (firstname === "") {
      setNameError("Name field cannot be empty");
    } else if (!nameRegexMin2.test(firstname)) {
      setNameError("Invalid Name");
    } else {
      setNameError("");
    }
    if (lastName === "") {
      setLastNameError("Last Name field cannot be empty");
    } else if (!nameRegexMin2.test(lastName)) {
      setLastNameError("Invalid Last Name");
    } else {
      setLastNameError("");
    }
  };
  const handlePostapiCall = () => {
    var data = {
      firstName: firstname,
      lastName: lastName,
      description: comment,
      email: email,
      phoneNumber: phoneno,
    };
    axios
      .post("https://smartsalon.in/api/enquiryForm,", data)
      .then(function (response) {
        // handle success
        console.log("cfdjdfjsjdfsjdfsj", response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  const location = useLocation()
  // const { data } = location.state
  const { data } = location.state;
  console.log("location.state",location.state)
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                //   dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        }
    ]
};
const brandsImg = [
  
  {
    galleryimg:'https://monsoonsalon.com/wp-content/uploads/2021/11/2B2A9228-scaled.jpg'
},
{
    galleryimg:'https://monsoonsalon.com/wp-content/uploads/2021/11/2B2A9262-scaled.jpg'
},
{
    galleryimg:'https://monsoonsalon.com/wp-content/uploads/2022/01/monsoon.jpeg'
},
{
    galleryimg:'https://monsoonsalon.com/wp-content/uploads/2021/11/2B2A9281-scaled.jpg'
},
{
    galleryimg:'https://monsoonsalon.com/wp-content/uploads/2021/01/0Y2A7155-scaled.jpg'
},
{
    galleryimg:'https://monsoonsalon.com/wp-content/uploads/2021/11/2B2A9235-scaled.jpg'
},
{
    galleryimg:'https://monsoonsalon.com/wp-content/uploads/2021/11/2B2A9236-scaled.jpg'
},    {
    galleryimg:'https://monsoonsalon.com/wp-content/uploads/2021/11/2B2A9234-scaled.jpg'
},
{
    galleryimg:'https://monsoonsalon.com/wp-content/uploads/2021/11/2B2A9230-scaled-e1638265979845.jpg'
},
{
    galleryimg:'https://monsoonsalon.com/wp-content/uploads/2021/01/1F2A2076-scaled.jpg'
},
{
    galleryimg:'https://monsoonsalon.com/wp-content/uploads/2021/11/2B2A9246-scaled.jpg'
},
{
    galleryimg:'https://monsoonsalon.com/wp-content/uploads/2021/11/2B2A9282-scaled.jpg'
},
{
    galleryimg:'https://monsoonsalon.com/wp-content/uploads/2021/11/2B2A9254-scaled.jpg'
},
 
   
]


  const handleChangeName = (e) => {
    setFirstName(e.target.value)
    // console.log('object')
  }
  const onchangeLastName = (e) => {
    // console.log('object')
    setLastName(e.target.value)
  }
  const handlecahngeEmail=(e)=>{
    setEmail(e.target.value)
  }
  const handleChangecomment = (e) => {
       setComment(e.target.value)
  }
  const handleChangephoneNo = (e) => {
    setPhoneno(e.target.value)
    console.log('object')
  }
  const onvalidation = () => {
    validatePhoneNumber();
    if (emailError === "" && phoneError === "") {
      handlePostapiCall();
    }
    if (captchaValue) {
      // Perform your form submission or other actions here.
      console.log("reCAPTCHA value:", captchaValue);
    } else {
      alert("Please complete the reCAPTCHA.");
    }
  };


  return (
    <>

<Helmet>
        <script>{`
   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
   })(window,document,'script','dataLayer','GTM-T37QZ7DB');
 `}</script>

        {/* Google Tag Manager (noscript) */}
         <noscript>
          {`<iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-T37QZ7DB"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>`}
        </noscript>
      </Helmet>
   
    <div>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
        <p className='salonaddress'>
        {"Best Salon in"}{data?.address2}
          
        </p>
        </div>
      <div className='mainContainer'
      // style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' ,marginTop:'130px'}}
      >
        <div style={{ width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '' }}>

          <Grid container
           sx={{ bgcolor: '' }}
           
            spacing={0}>
            <Grid item lg={3} sx={{ bgcolor: 'white',padding:'28px',border:'1px solid grey' }} >
              {/* <div>
        <p className='salonheadline'>Best Salon in Dwarka Sector 11 - Monsoon Salon Dwarka</p>
      </div> */}
              {/* <div style={{backgroundColor:'white',border:'1px solid grey'}}>
                <div
                //  className='addressContainer'
                style={{}}
                > */}
                <p style={{fontSize:'17px',fontWeight:'700',color:'#000000'}}>Salon Details</p>
                  <p className=''>
                   {data?.address}</p>
                  <p style={{}}>{data?.address2}</p>
                  <p>Open all days: 10am to 8pm</p>
                  <p>{data?.phoneNumber}</p>
                {/* </div> */}
             
              {/* </div> */}


            </Grid>
            {/* <div
            //  style={{borderWidth:'1px solid grey',backgroundColor:'red'}}
            style={{backgroundColor:'white',border:'1px solid grey',}}
            > */}

            <Grid item container lg={9} spacing={2} sx={{ bgcolor: 'white',padding:'28px',border:'1px solid grey' }}>
              <Grid item lg ={12} sx={{bgcolor:''}}>
                <Typography>Enquiry Now</Typography> 

              </Grid>
              <Grid item lg ={12} sx={{bgcolor:''}}>
                <Typography>Fill the form below for more details & we will get back to you shortly !</Typography> 

              </Grid>
            
              
              <Grid item lg={6} xs={12}  >
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  size="small"
                  value={firstname} 
                  required
                  fullWidth
                  inputProps={{ style: { color: "grey", fontSize: 18 } }}
                  InputLabelProps={{
                    style: { color: "#626A70", fontWeight: "700" },
                  }}
                  onChange={handleChangeName}
                />
                <Typography style={{ color: "red" }}>{nameError}</Typography>
              </Grid>
              <Grid item lg={6} xs={12} >

                <TextField
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  size="small"
                  value={lastName}
                  required
                  fullWidth
                  inputProps={{ style: { color: "grey", fontSize: 18 } }}
                  InputLabelProps={{
                    style: { color: "#626A70", fontWeight: "700" },
                  }}
                  onChange={onchangeLastName}

                />
                <Typography style={{ color: "red" }}>{lastNameError}</Typography>
              </Grid>
              <Grid item lg={6} xs={12}  >
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  size="small"
                  value={email}
                  required
                  fullWidth
                  inputProps={{ style: { color: "grey", fontSize: 18 } }}
                  InputLabelProps={{
                    style: { color: "#626A70", fontWeight: "700" },
                  }}
                  onChange={handlecahngeEmail}

                />
                <Typography style={{ color: "red" }}>{emailError}</Typography>
              </Grid>
              <Grid item lg={6} xs={12} >

                <TextField
                  id="outlined-basic"
                  label="Phone no"
                  variant="outlined"
                  size="small"
                  value={phoneno}
                  required
                  fullWidth
                  inputProps={{ style: { color: "grey", fontSize: 18 } }}
                  InputLabelProps={{
                    style: { color: "#626A70", fontWeight: "700" },
                  }}
                  onChange={handleChangephoneNo}

                />
                <Typography style={{ color: "red" }}>{phoneError}</Typography>
              </Grid>
              <Grid item lg={12} xs={12} >

                <TextField
                  id="outlined-basic"
                  label="Comment"
                  variant="outlined"
                  size="small"
                  value={comment}
                  required
                  fullWidth
                  multiline
                  rows={4}
                  inputProps={{ style: { color: "grey", fontSize: 18 } }}
                  InputLabelProps={{
                    style: { color: "#626A70", fontWeight: "700" },
                  }}
                  onChange={handleChangecomment}

                />
                <Typography style={{ color: "red" }}>{nameError}</Typography>
              </Grid>
              <Grid lg={12} sx={{bgcolor:'',display:'flex',justifyContent:'center',alignItems:'center'}}>
              <Button
                variant="contained"
                size="large"
                onClick={onvalidation}
                sx={{ marginLeft: 0, marginTop: 2, bgcolor: "grey" ,width:'160px'}}
              >
                Submit
              </Button>
              </Grid>
              </Grid>
            

            {/* </div> */}


          </Grid>







         
        </div>

      </div>
      <div className='gallery'>
          <p className='galleryText'>gallery</p>

        </div>
        <div style={{ }}>
                    <Slider {...settings}>
                        {
                            data?.images?.map((item, index) => {
                                return (
                                    <div 
                                      style={{ height: "110px", width: '50px',backgroundColor:'green' }}
                                    >
                                        <img src={item.appImageUrl
}
                                            // style={{ height: '50%', width: '100%' }}
                                            style={{ height: '300px', width: '600px',  }}
                                            alt='galleryimages'
                                        />
                                    </div>
                                )

                            })
                        }
                    </Slider>
                </div>



     
      






    </div>
    </>
  )
}










