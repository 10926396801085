const CustomSelect = ({ options, label,name, value, onChange,required ,error=null,helperText=null}) => {
  return (
    <>
     <div className="relative h-[45px] my-1">
  <select
    name={name}
    value={value}
    onChange={onChange}
    id={name}
    className={`block  h-full   px-[14px] py-[8.5px] w-full text-sm text-gray-900 bg-transparent border  rounded-[4px] ${error ?"border-red-600":"border-regel-gray/40"} appearance-none  focus:ring-0  focus:outline-2 outline-offset-[-1px] focus:outline-navyblue focus:border-navybluepeer`}
    >
    <option value=""></option>
    {options.map((elm, index) => (
      <option key={index} value={elm.value}>
        {elm.name}
      </option>
    ))}
  </select>
  <label
    htmlFor={name}
    className={`absolute text-sm ${error?"text-red-500":"text-regel-gray"} duration-300 transform scale-100  z-2 origin-[0] bg-white  px-2 
      peer-focus:px-2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
      ${value ? 'top-2 scale-75 -translate-y-4 top-1/2' : 'peer-placeholder-shown:top-[30%] top-[30%] peer-placeholder-shown:scale-100'}
      rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
  >
    {label}{required && <sup className="text-sm -mt-3">*</sup>}
  </label>
  <span className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
    <svg className="w-4 h-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M6 9l6 6 6-6"></path>
    </svg>
  </span>
  <p
  className="my-1 text-xs text-red-500 "
>
 {error&& <span className="font-medium">{helperText}</span> }
</p>
</div>

    </>
  );
};

export default CustomSelect;
