import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import SubFooter from '../subfooter'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import './style.css'
import Footer from '../footer/Footer';
import NewFooter from '../footer';

export default function CorporateEvent() {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    //   dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const brandsImg = [
      
        {
            brandUrl: 'https://monsoonsalon.com/wp-content/uploads/2019/12/Loreal.png'

        },
        // {
        //     brandUrl: 'https://www.geetanjalisalon.com/wp-content/uploads/2023/09/brands-1-300x129.jpg'

        // },
      
        {
            brandUrl: 'https://monsoonsalon.com/wp-content/uploads/2019/12/Loreal.png'

        },
        {
            brandUrl: 'https://monsoonsalon.com/wp-content/uploads/2019/12/Loreal.png'

        },
        {
            brandUrl: 'https://monsoonsalon.com/wp-content/uploads/2019/12/Loreal.png'

        },
        {
            brandUrl: 'https://monsoonsalon.com/wp-content/uploads/2019/12/Loreal.png'

        },
     
       
    ]
    return (
        <div style={{ backgroundColor: "" }}>
            <div  className='corporateEvent'
            // style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop:"20px" }}
            >
                {/* <Typography variant='h2'
                    style={{
                        fontSize: 42,
                        fontWeight: '500',
                        color: '#000000',
                        fontFamily: "Poppins"
                    }}> */}
                        <h2 className='corporateText'>
                        Corporate Events

                        </h2>
                        {/* </Typography> */}
            </div>
            <Container>
                <Typography style={{ fontSize: 17, color: '#626A70' }}>

                    Corporate activities are high-experienced events that require a range of offerings of fully integrated services. The services at Smart Salon can range from a photo shoot for a international magazine like
                </Typography>
                <Typography style={{ fontSize: 17, color: '#626A70' }}>Our team of professional stylists have worked with a lot of fashion designers too for their photo shoots, makeup and styling for Brand Events, Corporate Wellness, Magazines, TV shows, Exhibitions etc. Our professional stylists at Smart Salon are well versed on working in a tight deadline as shows like the </Typography>
                <Typography style={{ marginTop: 2, fontSize: 17, color: '#626A70' }}>
                    While working with <strong>Harper Bazaar</strong>, a fashion magazine, we did the makeup and hair for the editorial and were awarded by Harper. For
                    <strong>Cosmopolitian</strong>, we did the makeup and hair and Nail Art shoot. Similarly, for other magazines like
                    <strong>Shubh Yatra</strong>, the in-flight magazine of Air India, or
                    <strong>Spice Route</strong>, Smart Salon did the makeup and hair for editorial shoots.
                </Typography>
                <Typography style={{ fontSize: 17, color: '#626A70' }}>Taking the work to a totally different level, expert stylists at Smart Salon also did the
                    <strong >Makeup</strong> and <strong>Wills India Fashion Week</strong>, Asia’s biggest fashion and trade event. Similarly, at the
                    <strong> India Runway Week</strong>, Smart Salon, as official partners for hair and makeup, did over 95 looks in just two days of time.
                    <strong>Rod Anker</strong>, who to his credit has numerous awards ranging from <strong>Vogue</strong> colourist of the year 2012,
                    <strong>Elle Beauty Awards Judge</strong> 2011, 2012, 2013 and 2014, is the mentor behind the team at Smart Salon.
                </Typography>
            </Container>
            <div  className='corporateEvent'
            // style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop:"20px" }}s
            style={{textAlign:'center',fontSize:'16px'}}
            >
                {/* <Typography variant='h2'
                    style={{
                        fontSize: 42,
                        fontWeight: '500',
                        color: '#000000',
                        fontFamily: "Poppins"
                    }}> */}
                        <h2 className='corporateText'>
                        {/* Fashion Weeks */}
                        Corporate Wellness

                        </h2>
                        {/* </Typography> */}
            </div>
            <Container>

                <Typography style={{ fontSize: 17, color: '#626A70' }}>Organising welness programmes for your employees is an excellent way to boost the morale of your team and show your staff that they are valued. Moreover, it has been seen that regular events for your workforce help to lower absenteeism, enhance productivity, increased employee retention and commitment.</Typography>
                <Typography style={{ fontSize: 17, color: '#626A70' }}>We recently did Nail Art for
                    <strong>M3M India</strong>, a real-estate company, on Women’s Day. Here our expert stylists visited the company and did
                    <strong>Nail Art</strong> of female employees.
                </Typography>
                <Typography style={{ fontSize: 17, color: '#626A70' }}>
                    Our work is diversified and we provide various services like
                    <strong>Massage Therapy</strong> etc for corporates. We have a dedicated team that specialise in arranging such services. These programmes are tailored to meet specific needs of your valued workforce.
                </Typography>
                <Typography style={{ fontSize: 17, color: '#626A70' }}>Once finalised, our team of experts at Smart Salon reach the destination in quick time and offer these services for the dedicated workforce of your company. Such programmes also build rapport and is a way of expressing your dedication to them. Your workforce would feel valued and looked after.</Typography>
            </Container>
             <div  className='corporateEvent'
            // style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop:"20px" }}
            >
                {/* <Typography variant='h2'
                    style={{
                        fontSize: 42,
                        fontWeight: '500',
                        color: '#000000',
                        fontFamily: "Poppins"
                    }}> */}
                        <h2 className='corporateText'>
                        Fashion Weeks

                        </h2>
                        {/* </Typography> */}
            </div>
            <Container>
                <Typography style={{ fontSize: 17, color: '#626A70' }}>
                    Known as catwalk or runway, fashion weeks are the place where the trends are created by the designer and fashion make-up artists. The experts at Smart Salon have done a lot of Makeup and Hair for a number of fashion weeks across India. These are very
                    <strong>high pressure</strong> jobs as the time taken by a model to get a new look after a ramp walk is very less. A completely new look has to be created within a fraction of a second.

                </Typography>
                <Typography style={{ fontSize: 17, color: '#626A70' }}>Every fashion make-up artist need to have three essential qualities for his/her type of work. They should have an empathy with the designer’s style, they should be creative and able to devise new ideas and fast and confident enough of the make-up they are giving.</Typography>
                <Typography style={{ fontSize: 17, color: '#626A70' }}>Make-up experts at Smart Salon are an expertise in these concepts and have worked for various Fashion Weeks all across the country. One of our laudable works include
                    <stong>Makeup </stong>
                    <stong>Hair </stong>
                    <stong>Wills India Fashion Week</stong>
                    , Asia’s biggest fashion and trade event.
                </Typography>
                <Typography style={{ fontSize: 17, color: '#626A70' }}>
                    At the <strong>India Runway Week</strong>, Smart Salon, as official partners for <strong>Hair </strong>and
                    <strong>  Makeup</strong>
                    {/* </Typography> */}
                    {/* <Typography style={{fontSize:17,color:'#626A70'}}> */}
                    So if you are organising a Fashion Show or Event, Smart Salon is the right choice as we have the needed experience and expertise to make your work easier.
                </Typography>
            </Container>
            <Container>
                {/* <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: 8 }}>
                    <Typography variant='h2'
                        style={{
                            fontSize: 42,
                            fontWeight: '500',
                            color: '#000000',
                            fontFamily: "Poppins"
                        }}>Partner Brands</Typography>

                </Box> */}
                {/* <div style={{ marginTop: 10, marginBottom: '10px',height: '150px' ,}}>
                    <Slider {...settings}>
                        {
                            brandsImg.map((item, index) => {
                                return (
                                    <div 
                                      style={{ height: "150px", width: '50px',backgroundColor:'green' }}
                                    >
                                        <img src={item.brandUrl}
                                            style={{ height: '100%', width: '100%' }}
                                            alt='galleryimages'
                                        />
                                    </div>
                                )

                            })
                        }
                    </Slider>
                </div> */}
            </Container>

<NewFooter/>
        </div>
    )
}
