import React from 'react'

const PrivacyAndPolicy = () => {
  return (
    <div className='w-full mt-24 px-8'>
        <h1 className='text-4xl font-bold text-black text-center'>Privacy & Policy</h1>
        <p className='mt-4 text-sm'><a href='https://smartsalon.in/' className='font-bold text-lg'>Smart Salon</a> has adopted this Privacy Statement in order to inform you of its policies with respect to information collected from this website. Your use of this website constitutes your acceptance of this Privacy Statement and your consent to the practices it describes. When you visit the Smart Salon website, certain anonymous information about your visit is automatically logged, which may include information about the type of browser you use, the server name and IP address through which you access the Internet, the date and time you access the site, the pages you access while at the Smart Salon website, and the Internet address of the website, if any, from which you linked directly to the Smart Salon site. This information is not personally identifiable.</p>
        <h1 className='text-xl mt-4 font-bold text-black '>Personally Identifiable Information</h1>
        <p className='mt-4 text-sm'>Personally Identifiable Information is any information that concerns you individually and would permit someone to contact you, for example, your name, address, photo, telephone/fax number, e-mail address or any information you submitted to smartsalon.in that identifies you individually. smartsalon.in will not collect any personally identifiable information about you unless you provide it. Therefore, if you do not want smartsalon.in to obtain any personally identifiable information about you, do not submit it. You can visit and browse the Smart Salon website without revealing personally identifiable information about yourself. You may also choose to disclose personally identifiable information about yourself, which may be maintained as described below. smartsalon.in may collect personally identifiable information about you from its website by methods such as the following: E-mail and other voluntary communications - You may also choose to communicate with smartsalon.in through e-mail, via our website, by telephone, in writing, or though other means. Smart Salon collects the information in these communications and such information may be personally identifiable. Information Use smartsalon.in may use the personally identifiable information collected through its website primarily for such purposes as: processing, servicing or enforcing transactions and sending related communications, providing services and support to users, improving the website, including tailoring it to users' preferences, providing users with product or service updates, promotional notices and offers, etc information about Smart Salon and its affiliates, responding to your questions, inquiries, comments and instructions, maintaining the security and integrity of its systems.</p>
        <p className='mt-4 text-sm'>This privacy policy governs your use of the software application Smart Salon  ( “ Application” ) for Mobile devices created by our team. The Application obtains information you provide when you download and register the Application and any information you enter in the Application. This includes information that can identify you ( “ personal information” ) telephone number, photo and e-mail addresses, social media login.</p>
        <h1 className='text-xl mt-4 font-bold text-black '>Smart Salon  uses the collected data for various purposes: </h1>
        <ul className='ml-5'>
            <li className='list-decimal'> To notify you about changes to our Service.</li>
            <li className='list-decimal'>To allow you to participate in interactive features of our Service when you choose to do so.</li>
            <li className='list-decimal'>To provide customer support.</li>
            <li className='list-decimal'>To gather analysis or valuable information so that we can improve our Service.</li>
            <li className='list-decimal'>To monitor the usage of our Service.</li>
            <li className='list-decimal'>To detect, prevent and address technical issues.</li>
            <li className='list-decimal'>To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information.</li>
        </ul>
        <h1 className='text-xl mt-4 font-bold text-black '>Retention of Data</h1>
        <p className='mt-4 text-sm'>Smart Salon  will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies.

Smart Salon  will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
        <h1 className='text-xl mt-4 font-bold text-black '>Transfer of Data</h1>
        <p className='mt-4 text-sm'>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. If you are located outside Delhi, India and choose to provide information to us, please note that we transfer the data, including Personal Data, to Delhi, India and process it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
Smart Salon  will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
        <h1 className='text-xl mt-4 font-bold text-black '>Who you may choose to share your User Content with</h1>
        <ul className='mb-4'>
            <li> Who you may choose to share your User Content with If Smart Salon  is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy. <br></br>
            -To comply with a legal obligation.<br></br>

-To protect and defend the rights or property of Smart Salon .<br></br>

-To prevent or investigate possible wrongdoing in connection with the Service.<br></br>

-To protect the personal safety of users of the Service or the public.<br></br>

-To protect against legal liability.</li>


        </ul>
        <h1 className='text-xl font-bold text-black '>Security of Data</h1>
        <p className='mt-4 text-sm '>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.<br></br>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).<br></br>Following termination or deactivation of your User account, Smart Salon may retain your profile information and User Content for a commercially reasonable time, and for as long as we have a valid purpose to do so. In particular, Smart Salon may retain your information for the purpose of comply with its legal and audit obligations, and for backup and archival purposes.</p>
        <h1 className='text-xl font-bold text-black '>Disclosure Text for Inclusion in Privacy Policy</h1>
        <p className='mt-4 text-sm'>We may disclose to third party services certain personally identifiable information listed below:<br></br>

-information you provide us such as name, email, mobile phone number.<br></br>

-information we collect as you access and use our service, including device information, location and network carrier.</p>
        <h1 className='text-xl font-bold text-black '>This information is shared with third party service providers so that we can:</h1>
        <p className='mt-4 text-sm'>-personalize the app for you.<br></br>
        -perform behavioral analytics.<br></br></p>
        <h1 className='text-xl font-bold text-black '>Any Updates to this Privacy Policy</h1>
        <p className='mt-4 text-sm'>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy. Please review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
        <h1 className='text-xl font-bold text-black '>Contact Us</h1>
        <p className='mt-4 text-sm'>For any questions about this Privacy Policy, please contact us at +919315743367</p>
    </div>
  )
}

export default PrivacyAndPolicy