import { Container, Typography } from '@mui/material'
import React from 'react'
import Footer from '../footer/Footer'
import './video.css'
import NewFooter from '../footer'

export default function Video() {
  const videoLink = [
    {
      youtubeVideourl: 'https://monsoonsalon.com/wp-content/uploads/2021/08/1st-Branch-Ambience-1-1-1.mp4'
    },
    {
      youtubeVideourl: 'https://monsoonsalon.com/wp-content/uploads/2021/01/2nd-branch-sec-70.mp4'
    },

    {
      youtubeVideourl: 'https://monsoonsalon.com/wp-content/uploads/2021/01/4th-branch-noida-45.mp4'
    },
    {
      youtubeVideourl: 'https://res.cloudinary.com/dkvmvyvnx/video/upload/v1696049750/MONSOON_SALON___BEST_SALON____rrjq9d.mp4'
    },

    {
      youtubeVideourl: 'https://monsoonsalon.com/wp-content/uploads/2021/01/3rd-branch-soutex.mp4'
    },
    {
      youtubeVideourl: 'https://res.cloudinary.com/dkvmvyvnx/video/upload/v1696050283/Keratin_Treatment___Hair_Colour___Monsoon_Salon_tee1bl.mp4'
    },
    {
      youtubeVideourl: 'https://res.cloudinary.com/dkvmvyvnx/video/upload/v1696050765/Monsoon_Salon_in_Guwahati_Assam_eb12aw.mp4'
    },
    {
      youtubeVideourl: 'https://monsoonsalon.com/wp-content/uploads/2021/01/2nd-branch-sec-70.mp4'
    },
    {
      youtubeVideourl:'https://res.cloudinary.com/dkvmvyvnx/video/upload/v1696051877/monsoon_Solon_ad_film_3_a_film_by_prachar.india_wuclio.mp4'
    },
    {
      youtubeVideourl: 'https://res.cloudinary.com/dkvmvyvnx/video/upload/v1695992558/Nusrat_Jahan___Grand_Opening___Monsoon_Salon___Bhawanipore_Kolkata_exvljy.mp4'
    },


  ]
  return (
    <div style={{}}>
      <Typography sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        variant='h4'>Videos</Typography>
         {/* <div className="video-gallery-container">
      {videoLink.map((item, index) => (
        <div key={index} className="video-item">
          <video controls
          className='videurlStyle'>
            <source src={item?.youtubeVideourl} type="video/mp4" />
          </video>
        </div>
      ))}
    </div> */}

        <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', }}>
          <div style={{maxWidth:'900px',width:'100%',display: 'flex',gap:"20px",flexWrap:'wrap'}}>
            {
              videoLink?.map((item, index) => {
                return (
                  <div className='videoContainer'>
                    <video controls
                      style={{ height: '400px',width:'400px' }}>
                      <source src={item?.youtubeVideourl} type="video/mp4" />
                    </video>
                  </div>
                )

            })
          }
        </div>
      </div>

      <NewFooter />

    </div>
  )
}
