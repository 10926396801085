import React from 'react'

export default function Career() {
  return (
    <div style={{backgroundColor:'red'}}>
        <p>fdjkjdfjkhjkdfjkdf</p>
      
    </div>
  )
}
