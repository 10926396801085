import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "./bookappointment.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import LOCAL_IMAGES from "../utils/localImages";
import { Helmet } from "react-helmet";
import NewFooter from "../footer";
import { Link } from "react-router-dom";
import { brandsImg } from "../utils/dummydata";
import toast from "react-hot-toast";
import Banner from "../../components/banners/Banner";
import CustomInput from "../../components/customInputs/CustomInput";
import CustomSelect from "../../components/customInputs/CustomSelect";
import CustomTextArea from "../../components/customInputs/CustomTextArea";
import { postApiData } from "../../utils/services";

const initialData = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  date: "",
  time: "",
  serviceType: "",
  salonType: "",

  city: "",
  description: "",
};
export default function BookAppointment() {
  const salonData = [
    {
      name: "Monsoon",
    },
    {
      name: "Monsoon Pro",
    },
    {
      name: "Smart Salon",
    },
  ];
  const salonService = [
    {
      name: "Hair",
    },
    {
      name: "Beauty",
    },
    {
      name: "Make up",
    },
    {
      name: "Nails",
    },
  ];

  const citiesJson = [
    {
      name: "Andhra Pradesh",
    },
    // {
    //   name: "Arunachal Pradesh",
    // },
    {
      name: "Assam",
    },
    {
      name: "Bihar",
    },
    {
      name: "Chhattisgarh",
    },
    // {
    //   name: "Goa",
    // },
    {
      name: "Gujarat",
    },
    {
      name: "Haryana",
    },
    {
      name: "Himachal Pradesh",
    },
    {
      name: "Jharkhand",
    },
    {
      name: "Karnataka",
    },
    {
      name: "Kerala",
    },
    {
      name: "Madhya Pradesh",
    },
    {
      name: "Maharashtra",
    },
    {
      name: "Manipur",
    },
    {
      name: "Meghalaya",
    },
    // {
    //   name: "Mizoram",
    // },
    // {
    //   name: "Nagaland",
    // },
    {
      name: "Odisha",
    },
    {
      name: "Punjab",
    },
    {
      name: "Rajasthan",
    },
    // {
    //   name: "Sikkim",
    // },
    {
      name: "Tamil Nadu",
    },
    {
      name: "Telangana",
    },
    {
      name: "Tripura",
    },
    {
      name: "Uttar Pradesh",
    },
    {
      name: "Uttarakhand",
    },
    {
      name: "West Bengal",
    },
    {
      name: "Andaman and Nicobar Islands",
    },
    {
      name: "Chandigarh",
    },
    // {
    //   name: "Dadra and Nagar Haveli and Daman and Diu",

    // },
    // {
    //   name: "Lakshadweep",

    // },
    {
      name: "Delhi",
    },
    // {
    //   name: "Puducherry",
    // },
    {
      name: "Ladakh",
    },
    // {

    //   name: "Lakshadweep",
    // },
  ];
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          //   dots: true
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    date: "",
    time: "",
    serviceType: "",
    city: "",
  });
  const [formValues, setFormValues] = useState(initialData);

  const [allcity, setallCity] = useState([]);

  const labelStyles = {
    color: "grey",
    // Change this to the color you desire at time and date
  };

  const handleChange = (e, key = "") => {
    if (key && typeof key !== "object") {
      setFormValues((prev) => ({
        ...prev,
        [key]: e,
      }));
      setFormErrors((prev) => ({
        ...prev,
        [key]: "",
      }));
    } else {
      const { name, value } = e.target;
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
      setFormErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };
  useEffect(() => {
    const { state, salonType } = formValues;
    if (state && salonType) {
      axios
        .post("https://smartsalon.in/api/suggestionForParlors", {
          stateName: state,
          salonType: salonType,
        })
        .then((response) => {
          setallCity(response?.data?.data?.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [formValues.state, formValues.salonType]);

  const BookAppointmentApiCall = () => {
    const { state, salonType, ...data } = formValues;

   
      postApiData("appointmentForm",data,(res)=>{
        toast.success("response submit sucessfully");
        setFormValues(initialData);
      },(err)=>{
        console.log(err);  
      })
     
  };
  const validateForm = () => {
    const Regex = /^[6-9][0-9]{9}$/;

    const emailRgx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const errors = {};

    if (!formValues.firstName || formValues.firstName.trim() === "") {
      errors.firstName = "First Name is required";
    }
    if (!formValues.lastName || formValues.lastName.trim() === "") {
      errors.lastName = "Last Name is required";
    }
    if (!formValues.email || !emailRgx.test(formValues?.email)) {
      errors.email = "Email is required";
    }
    if (!formValues.phoneNumber || !Regex.test(formValues?.phoneNumber)) {
      errors.phoneNumber = "Phone number is required";
    }
    if (!formValues.state || formValues.state.trim() === "") {
      errors.state = "State is required";
    }
    if (!formValues.city || formValues.city.trim() === "") {
      errors.city = "City is required";
    }
    if (!formValues.date) {
      errors.date = "Date is required";
    }
    if (!formValues.time) {
      errors.time = "Time is required";
    }
    if (!formValues.salonType || formValues.salonType.trim() === "") {
      errors.salonType = "Salon Type is required";
    }
    if (!formValues.serviceType || formValues.serviceType.trim() === "") {
      errors.serviceType = "Service Type is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const submithandle = () => {
    if (!validateForm()) {
      return;
    }

    BookAppointmentApiCall();
  };
  const formFields = [
    {
      type: "TextField",
      id: "outlined-basic",
      label: "First Name",
      name: "firstName",
      value: formValues?.firstName,
      error: !!formErrors.firstName,
      helperText: formErrors.firstName,
      required: true,
    },
    {
      type: "TextField",
      id: "outlined-basic",
      label: "Last Name",
      name: "lastName",
      value: formValues?.lastName,
      error: !!formErrors.lastName,
      helperText: formErrors.lastName,
      required: true,
    },
    {
      type: "TextField",
      id: "outlined-basic",
      label: "Email",
      name: "email",
      value: formValues?.email,
      error: !!formErrors.email,
      helperText: formErrors.email,
      required: true,
    },
    {
      type: "TextField",
      id: "outlined-basic",
      label: "Phone no",
      name: "phoneNumber",
      value: formValues?.phoneNumber,
      error: !!formErrors.phoneNumber,
      helperText: formErrors.phoneNumber,
      required: true,
    },
    {
      type: "DatePicker",
      label: "Appointment Date *",
      name: "date",
      value: formValues?.date,
      error: !!formErrors.date,
      helperText: formErrors.date,
    },
    {
      type: "TimePicker",
      label: "Appointment Time *",
      name: "time",
      value: formValues?.time,
      error: !!formErrors.time,
      helperText: formErrors.time,
    },
    {
      type: "Select",
      label: "State*",
      name: "state",
      value: formValues?.state,
      options: citiesJson?.map((item) => ({
        value: item.name,
        name: item.name,
      })),
      error: !!formErrors.state,
      helperText: formErrors.state,
    },
    {
      type: "Select",
      label: "SalonType*",
      name: "salonType",
      value: formValues?.salonType,
      options: salonData?.map((item) => ({
        value: item.name,
        name: item.name,
      })),
      error: !!formErrors.salonType,
      helperText: formErrors.salonType,
    },
    {
      type: "Select",
      label: "City *",
      name: "city",
      value: formValues?.city,
      options: allcity?.map((item) => ({
        value: item?.address2,
        name: item?.address2,
      })),
      error: !!formErrors.city,
      helperText: formErrors.city,
    },
    {
      type: "Select",
      label: "Service Interested In*",
      name: "serviceType",
      value: formValues?.serviceType,
      options: salonService?.map((item) => ({
        value: item.name,
        name: item.name,
      })),
      error: !!formErrors.serviceType,
      helperText: formErrors.serviceType,
    },
    {
      type: "TextArea",
      label: "Comments or Message",
      name: "description",
      value: formValues?.description,
      multiline: true,
      rows: 3,
      required: false,
    },
  ];

  console.log(formValues);
  return (
    <>
      <Helmet>
        <title>
          Top Salons in India | Smart Salon | Book an Appointment Today
        </title>
        <meta
          name="description"
          content="Smart Salon - India's leading chain of top-rated salons. Book an appointment now and pamper yourself with top-notch services from our expert stylists - available pan India. Book now!
"
        />
        <meta
          name="keywords"
          content="schedule book an appointment, salon appointment booking, book online salon appointment"
        />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-870359358"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-870359358');
        `}
        </script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-6SYNR8TGVJ"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-6SYNR8TGVJ');
        `}
        </script>
        {/* Google Tag Manager */}
      <script>{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-T37QZ7DB');
      `}</script>

      {/* Google Tag Manager (noscript) */}
      <noscript>
       {` <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T37QZ7DB"
          height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}>
        </iframe>`}
      </noscript>
      </Helmet>
      <Box>
        {/* <img
        src="https://monsoonsalon.com/wp-content/uploads/2021/01/0Y2A0396-scaled-e1638956901369.jpg"
        alt=""
        className="responsive"
      /> */}
        <div className="">
          <Banner image={LOCAL_IMAGES.monsoonsalonbook} />
        </div>
        <div className=" my-9" style={{ bgcolor: "white" }}>
          <div className="lg:my-9 mx-3 appointmentContainer">
            <div className="bookappointment">
              <span className="onlinetext"> BOOK AN APPOINTMENT ONLINE</span>
              <div
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <img src={LOCAL_IMAGES.lineIcon} alt=''
         /> */}
              </div>
            </div>

            <Box className="ourbooking">
              <Typography className="bookingtext">
                Our online bookings service operates between 10:00a.m. and
                6:00p.m.
              </Typography>
            </Box>
            <div
              style={{
                bgcolor: "white",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                backgroundColor: "white",
              }}
            >
              <Typography style={{ fontSize: 17, color: "#626A70" }}>
                Your data is safe with us! We will only use your details to
                process your salon booking, and won’t share them with third
                parties.
              </Typography>
            </div>
          </div>
          <div className="bg-white px-3 md:px-6">
            <div className="flex flex-col lg:flex-row gap-6 item justify-center">
              <div className="xl:w-1/2 mt-6 w-full">
                <div className=" grid gap-6 grid-cols-1 md:grid-cols-2">
                  {formFields.map((field, index) => {
                    switch (field.type) {
                      case "TextField":
                        return (
                          <CustomInput
                            key={index}
                            label={field.label}
                            name={field.name}
                            value={field.value}
                            onChange={handleChange}
                            error={field.error}
                            helperText={field.helperText}
                            required={field.required}
                          />
                        );
                      case "TextArea":
                        return (
                          <CustomTextArea
                            key={index}
                            label={field.label}
                            name={field.name}
                            value={field.value}
                            onChange={handleChange}
                            required={field.required}
                          />
                        );
                      case "DatePicker":
                        return (
                          <div className="relative">
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              key={index}
                            >
                              <DatePicker
                                sx={{ border: "none", outline: "none" }}
                                label={
                                  <span style={labelStyles}>{field.label}</span>
                                }
                                value={field.value}
                                onChange={(val) =>
                                  handleChange(val, field.name)
                                }
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    fullWidth: true,
                                    error:field.error
                                   
                                  },
                                }}
                              />
                            </LocalizationProvider>
                            {field.error && (
                              <p className="my-1 absolute -bottom-6 text-xs text-red-500 ">
                                <span className="font-medium">
                                  {field.helperText}
                                </span>
                              </p>
                            )}
                          </div>
                        );
                      case "TimePicker":
                        return (
                          <div className="relative   lg:-mt-1 ">
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            key={index}
                          >
                            <DemoContainer components={["TimePicker"]}>
                              <TimePicker
                                 sx={{ border: "none", outline: "none",height:"50px" }}

                                label={
                                  <span style={labelStyles}>{field.label}</span>
                                }
                                value={field.value}
                                onChange={(val) =>
                                  handleChange(val, field.name)
                                }
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    fullWidth: true,
                                    error:field.error

                                  },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          {field.error && (
                              <p className="my-1 absolute -bottom-6 text-xs text-red-500 ">
                                <span className="font-medium">
                                  {field.helperText}
                                </span>
                              </p>
                            )}
                            </div>
                        );
                      case "Select":
                        return (
                          <CustomSelect
                            key={index}
                            label={field.label}
                            name={field.name}
                            value={field.value}
                            onChange={handleChange}
                            error={field.error}
                            helperText={field.helperText}
                            required={field.required}
                            options={field.options}
                          />
                        );
                      default:
                        return null;
                    }
                  })}
                </div>
                <div className=" flex  mt-5 gap-3 flex-wrap items-center">
                  <button
                    onClick={submithandle}
                    className="inline-flex w-full sm:w-auto h-fit items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap drop-shadow-md bg-neutral-400 border border-transparent rounded-md shadow-sm hover:bg-black  "
                  >
                                         Book Appointment

                  </button>
                   
                
                  <div className="bg-white flex items-center mx-auto md:mx-0">
                    <div className="ortextcall flex items-center  ">
                      <span className="mr-1">
                        Or Call us @
                        <span className="phoneno">+917703900678</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* <p className="ortextcall">Call us @   <p className="phoneno">   +919919898006</p>  <p className="phoneno">,+918826345211</p> </p> */}
              <div className="w-[80%] lg:w-[40%] mx-auto">
              <div className="flex w-full items-center ">
                <img
                  src={LOCAL_IMAGES.appointment}
                  alt="salonbooking"
                  className="mx-auto"
                />
              </div>
</div>
              {/* 
         
          */}
            </div>
            <div className="">
              <h1 className="bookappointmentsalon">
                Book an Appointment at Smart Salon
              </h1>
              <p className="discriptionText">
                Contact Smart Salon to Schedule an Appointment.Smart Salon, a
                leading brand in the beauty market, goes above and above to
                provide each client a fresh new look. Smart Salon is proud to be
                the trusted hair and beauty treatment partner of thousands of
                loyal customers at our 117+ locations in India and overseas. Let
                us take care of your appearance so that you can relax and enjoy
                yourself, whether you're getting ready for a party, festival, or
                just a regular date.
              </p>
              <p className="discriptionText">
                In need of a new look for the holiday season? Or perhaps you're
                looking to reinvent yourself. Then don't hesitate any longer;
                make an appointment at Smart Salon right away and watch as you
                emerge from the salon a new, improved person. Making an
                appointment at Smart Salon couldn't be simpler. In order for our
                stylists and specialists to better serve you, please fill out
                the brief form above. Afterward, an executive will contact you
                to confirm your appointment. Your process of metamorphosis has
                officially begun.
              </p>
              <p className="discriptionText">
                <Link to="/salonfinder" style={{ color: "#d3af37" }}>
                  Our Salon Finder
                </Link>{" "}
                makes it simple to find and schedule an appointment at a Smart
                Salon near you. Our goal is for you to leave our salon happy and
                feeling fabulous after we've given you a new look and answered
                all your questions about hair and makeup. Schedule your visit
                right away!
              </p>
            </div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 8,
                backgroundColor: "white",
              }}
            >
              <Typography
                variant="h2"
                style={{
                  fontSize: 42,
                  fontWeight: "500",
                  color: "#323232",
                  fontFamily: "Poppins",
                }}
              >
                Partner Brands
              </Typography>
            </Box>
            {/* <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', }}>
      <img src={LOCAL_IMAGES.lineIcon} alt=''
         />
           </Box> */}
            <div
            // style={{ marginTop: 10, marginBottom: '10px', height: '100px', backgroundColor: '', justifyContent: 'center', alignItems: 'center' }}
            >
              <Slider {...settings}>
                {brandsImg.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "50px",
                        width: "50px",
                        backgroundColor: "green",
                      }}
                    >
                      <img
                        src={item.brandUrl}
                        style={{ height: "60%", width: "50%" }}
                        alt="galleryimages"
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>

        {/* <Footer/> */}
        <NewFooter />
      </Box>
    </>
  );
}
